<template>
  <div class="admin-dashboard">
    <AdminHeader />
    <main>
      <b-container>
        <div class="mt-5">
          <b-card>
            <b-card-body class="p-0">
              <b-card-title>Manage Users</b-card-title>
              <b-alert :show="!!errorMessage" variant="danger">{{
                errorMessage
              }}</b-alert>
              <b-table striped responsive ref="table" stacked="lg" :fields="fields" :items="items">
                <template #cell(userId)="data">
                  <a
                    class="btn btn-outline-info"
                    :href="`/admin/${data.item.userId}/dashboard`"
                    >Manage</a
                  >
                </template>
              </b-table>
            </b-card-body>
          </b-card>
        </div>
      </b-container>
    </main>
  </div>
</template>

<script>
import AdminHeader from "@/components/admin/Header.vue";

export default {
  name: "ManageUsers",
  components: {
    AdminHeader,
  },
  data() {
    return {
      items: [],
      fields: [
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Username",
          key: "username",
        },
        {
          label: "Published?",
          key: "isPublished",
        },
        {
          label: "Official",
          key: "isOfficial",
        },
        {
          label: "Position",
          key: "position",
        },
        {
          label: "",
          key: "userId",
        },
      ],
      loading: false,
      errorMessage: "",
    };
  },
  computed: {},
  async mounted() {
    let data = await this.$store.dispatch("candidates/getAllCandidates", null);
    this.items = data;
  },
  methods: {},
};
</script>
